import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog articles" />
      <h1 className="title">Blog articles</h1>
      <hr />
      {data.allMarkdownRemark.edges.map(({ node: post }) => {
        return (
          <section key={post.id} className="section">
            <div className="container">
              <h1 className="title">
                <Link to={`/blog/${post.frontmatter.slug}/`}>{post.frontmatter.title}</Link>
              </h1>
              <h2 className="subtitle">
                {post.frontmatter.date} - {post.fields.readingTime.text}
              </h2>
              <div className="content" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </div>
          </section>
        );
      })}
    </Layout>
  );
};
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            slug
          }
          excerpt(format: HTML)
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
